import logo from '@/assets/logo.svg'
import type { PropsWithChildren } from 'react'
import type React from 'react'
import { NavLink } from 'react-router-dom'

const Header: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <header className="container z-20 mx-auto w-full px-10 py-6">
      <div className="flex w-full flex-col items-center justify-between space-y-3 lg:flex-row lg:space-y-0">
        <NavLink
          className="flex items-center text-2xl font-bold text-indigo-500 no-underline hover:no-underline lg:text-4xl"
          to="https://e.vtstg233.cc/"
        >
          <img src={logo} className="mr-3 h-16 w-16" alt="Qwerty Learner Logo" />
          <h1>Qwerty Learner</h1>
        </NavLink>
        <p>致敬那些键盘工作者 - VTSTG233</p>
        <p>
          你可以通过邮箱联系我:{' '}
          <a href="mailto:admin@vtstg233.cc" target="_blank" rel="noreferrer" style={{ color: 'red' }}>
            admin@vtstg233.cc
          </a>
        </p>
        <a href="https://top.vtstg233.cn" target="_self" title="VTSTG233的导航站" style={{ color: 'red' }}>
          <ins>VTSTG233的导航站(点击进入)</ins>
        </a>
        <p>
          <ins>请记得在设置里面导出数据保存你的进度！</ins> [暂未支持移动端]
        </p>
        <nav className="my-card on element flex w-auto content-center items-center justify-end space-x-3 rounded-xl bg-white p-4 transition-colors duration-300 dark:bg-gray-800">
          {children}
        </nav>
      </div>
    </header>
  )
}

export default Header
